import { useState, useEffect, lazy, Suspense } from 'react';
import useAxios from '../../../hooks/useAxios';
import useToasts from '../../../hooks/useToasts';
import Card from './card';
import Bar from './bar';
import TopBar from './top-bar';
import { getAllAccounts, getAccount, confirmAccount } from '../../../_util/api';
import { winterblessedCamille } from '../../imageLoader/images';

const ListCard = lazy(() => import('./list.card'));
const PopUp = lazy(() => import('./PopUp'));

const Listing = () => {
  const { addToast } = useToasts();
  const [accounts, setAccounts] = useState([]);
  const [sortedAccounts, setSortedAccounts] = useState([]);
  const [requestFilter, setRequestFilter] = useState([]);
  const [apiResponded, setApiResponded] = useState(false);
  const [popupData, setPopUpData] = useState(false);

  const accountPurchasedId = localStorage.getItem('setAccountPurchasedId');

  const { isLoading: loadingAccounts, makeRequest } = useAxios({
    onSuccess: (d) => {
      setAccounts(d.accounts);
      setApiResponded(true);
    },
    onError: (err) => {
      addToast(err, 'danger');
    },
  });

  const { isLoading: isConfirmingOrder, makeRequest: confirmAccountRequest } = useAxios({
    onSuccess: (d) => {
      addToast(
        'Account purchased successfully! Login details have been sent to your email.',
        'success',
      );
    },
    onError: (err) => {
      addToast(err, 'danger');
    },
  });

  const { isLoading: isGettingOrder, makeRequest: getAccountRequest } = useAxios({
    onSuccess: (d) => {
      localStorage.removeItem('setAccountPurchasedId');
      confirmAccountRequest(confirmAccount(d._id));
    },
    onError: (err) => {
      localStorage.removeItem('setAccountPurchasedId');
      addToast(err, 'danger');
    },
  });

  useEffect(() => {
    if (!accountPurchasedId) return;
    if (accountPurchasedId) {
      getAccountRequest(getAccount(accountPurchasedId));
    }
  }, []);

  useEffect(() => {
    const backdrop = document.querySelector('.modal-backdrop');
    if (backdrop) {
      backdrop.remove(); // Remove the modal backdrop if user is not null
      const body = document.querySelector('body');
      body.style.overflow = 'auto';
      body.style.padding = '0px';
    }
  }, []);

  return (
    <>
      <Suspense>
        <PopUp popupData={popupData} addToast={addToast} />
      </Suspense>
      <div className="accounts">
        <div className="container">
          <h2 className="fs-1 d-flex justify-content-center my-golden-text">Our Accounts</h2>
          <span style={{ color: '#c1c1c1' }} className="d-flex justify-content-center">
            for League of Legends.
          </span>

          <div className="row" style={{ marginTop: '2rem' }}>
            <Bar
              requestFilter={requestFilter}
              setRequestFilter={setRequestFilter}
              makeRequest={makeRequest}
              getAllAccounts={getAllAccounts}
            />
            <div className="mt-md-3 col-lg-9 col-md-12 col-sm-12">
              <TopBar
                accounts={accounts}
                sortedAccounts={sortedAccounts}
                setSortedAccounts={setSortedAccounts}
              />
              <div
                className="alert alert-success text-center position-relative"
                role="alert"
                style={{
                  fontSize: '1rem',
                  fontWeight: '500',
                  padding: '0',
                  height: '70px', // Set a fixed height for the banner
                  overflow: 'hidden', // Hide the overflow
                  border: 'double 1px transparent', // Double border with transparent color
                  borderRadius: '10px', // Add border-radius to the container
                  backgroundImage:
                    'linear-gradient(white, white), linear-gradient(to right, #FFD956, #FFFAD8)', // Gradient background
                  backgroundOrigin: 'border-box',
                  backgroundClip: 'content-box, border-box',
                }}
              >
                <img
                  src={winterblessedCamille}
                  alt="Holiday Discount"
                  style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                    objectPosition: 'center -3rem', // Move the image up by 10 pixels
                    borderRadius: '10px', // Ensure the image also has the same border-radius
                  }}
                />
                <div
                  className="position-absolute w-100 h-100 d-flex align-items-center justify-content-center"
                  style={{
                    top: 0,
                    left: -285,
                    color: '#00B67A',
                    fontSize: '1.15rem',
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                  }}
                >
                  🎉 25% Holiday Discount Event! 🎉
                </div>
              </div>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="grid"
                  role="tabpanel"
                  aria-labelledby="grid-tab"
                >
                  {!apiResponded && (
                    <div className="d-flex">
                      <div
                        className="spinner-border m-auto text-primary mt-4"
                        style={{
                          width: '2rem',
                          height: '2rem',
                          borderWidth: '0.2em',
                        }}
                      >
                        <span className="sr-only"></span>
                      </div>
                    </div>
                  )}

                  {apiResponded && (
                    <Card
                      sortedAccounts={sortedAccounts}
                      setPopUpData={setPopUpData}
                      loadingAccounts={loadingAccounts}
                    />
                  )}
                </div>

                {/*<div
                  className="tab-pane fade"
                  id="list"
                  role="tabpanel"
                  aria-labelledby="list-tab"
                >
                  {!apiResponded && (
                    <div className="d-flex">
                      <div className="m-auto">
                        <div className="spinner-border text-primary">
                          <span className="sr-only"></span>
                        </div>
                      </div>
                    </div>
                  )}
                  {apiResponded && (
                    <Suspense>
                      <ListCard
                        accounts={accounts}
                        setPopUpData={setPopUpData}
                      />
                    </Suspense>
                  )}
                  </div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Listing;
